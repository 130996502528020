import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircleQuestionMark from './assets/CircleQuestionMark';

const useStyles = makeStyles({
  button: {
    padding: 0,
    minWidth: 30,
    marginTop: 5,
    marginBottom: 5,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const useTooltipStyles = makeStyles({
  tooltip: {
    backgroundColor: '#000000',
    color: '#ffffff',
    fontSize: 14,
    lineHeight: '17px',
    fontWeight: 'bold',
    maxWidth: 'none',
    padding: 11,
  },
  arrow: {
    color: '#000000',
  },
});

export default ({ title, ...props }) => {
  const classes = useStyles({});
  const tooltipClasses = useTooltipStyles({});
  return (
    <Tooltip
      title={title}
      placement="top"
      arrow={true}
      classes={tooltipClasses}
      enterTouchDelay={25}
      {...props}
    >
      <Button className={classes.button}>
        <CircleQuestionMark />
      </Button>
    </Tooltip>
  );
};
