export const calculateOutOfPocketCost = ({
  vetBill,
  deductible,
  reimbursementPercentage,
  reimbursementLimit,
}) => {
  if (vetBill <= deductible) {
    return vetBill;
  }

  const coverage = (vetBill - deductible) * reimbursementPercentage;

  if (
    reimbursementLimit === 'Unlimited' ||
    reimbursementLimit === 'ConditionSpecific'
  ) {
    return vetBill - coverage;
  }

  if (coverage > reimbursementLimit) {
    return vetBill - reimbursementLimit;
  }

  return deductible + (vetBill - deductible) * (1 - reimbursementPercentage);
};

export const calculateInsuranceCoverage = ({
  vetBill,
  deductible,
  reimbursementPercentage,
  reimbursementLimit,
}) => {
  if (vetBill <= deductible) {
    return 0;
  }

  const coverage = (vetBill - deductible) * reimbursementPercentage;

  if (
    coverage <= reimbursementLimit ||
    reimbursementLimit === 'Unlimited' ||
    reimbursementLimit === 'ConditionSpecific'
  ) {
    return coverage;
  }

  return reimbursementLimit;
};

export const calculateRemainingReimbursement = (inputs) => {
  const { reimbursementLimit } = inputs;

  if (reimbursementLimit === 'Unlimited') {
    return 'Unlimited';
  }

  if (reimbursementLimit === 'ConditionSpecific') {
    return 'ConditionSpecific';
  }

  const insuranceCovers = calculateInsuranceCoverage(inputs);

  return Math.min(reimbursementLimit - insuranceCovers, reimbursementLimit);
};
