import React from 'react';

function SvgComponent(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8 12.61a.781.781 0 100-1.563.781.781 0 000 1.562z"
        fill="#c2c2c2"
      />
      <path
        d="M8 0C3.579 0 0 3.578 0 8c0 4.421 3.578 8 8 8 4.421 0 8-3.578 8-8 0-4.421-3.578-8-8-8zm0 14.75A6.746 6.746 0 011.25 8c0-3.73 3.019-6.75 6.75-6.75 3.73 0 6.75 3.019 6.75 6.75 0 3.73-3.019 6.75-6.75 6.75z"
        fill="#c2c2c2"
      />
      <path
        d="M8 4.016a2.503 2.503 0 00-2.5 2.5.625.625 0 101.25 0c0-.69.56-1.25 1.25-1.25s1.25.56 1.25 1.25c0 .689-.56 1.25-1.25 1.25a.625.625 0 00-.625.625v1.562a.625.625 0 101.25 0V8.937A2.504 2.504 0 0010.5 6.516c0-1.379-1.121-2.5-2.5-2.5z"
        fill="#c2c2c2"
      />
    </svg>
  );
}

export default SvgComponent;
